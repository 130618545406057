<template>
  <div class="CmsTop">
    <div
      v-if="group"
      id="applicantWrap" class="mt-5 tableWrap">
      <v-form-select
        itemStyle="line"
        label="申請者一覧"
        suffix="年度"
        labelGridClass="max-w80"
        inputClass="max-w120"
        labelSize="title"
        size="sm"
        :options="yearOptions.app"
        :hasDefault="false"
        v-model="selectedYears.app"
        @change="yearChange('app')"
      >
      </v-form-select>
      <b-table
        show-empty
        :border="false"
        :fields="heads.app"
        :items="showData.app"
        thead-class="tableHead"
        tbody-tr-class="dataWrap"
        table-class="icon-white"
        id="applicantTable"
      >
        <template v-slot:cell(editButton)="data">
          <b-link
            :to="'/cms/applications/list/'+selectedYears.app+'/'+data.item.typeId">
            助成申請
          </b-link>
        </template>
        <template v-slot:empty="scope">
          <p class="none-data">登録されているプログラムがありません。</p>
        </template>
      </b-table>
    </div>

    <div
      v-if="evaluation"
      id="evaluationWrap" class="mt-5 tableWrap">
        <div class="row line">
          <div class="col-sm-3 max-w80 ml-3"><label class="title">審査対象一覧</label></div>
        </div>
        <b-table
          show-empty
          :border="false"
          :fields="evalsHeader"
          :items="evals"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          table-class="icon-white"
          id="cmsTopEvalsTable"
        >
          <template v-slot:cell(term)="data">
            {{data.item.dateFrom}} ～ {{data.item.dateTo}}
          </template>
          <template v-slot:cell(is_not_done)="data">
            {{data.item.all_count - data.item.is_done}}
          </template>
          <template v-slot:cell(link)="data">
            <b-link
              :to="`/cms/evaluation/list/${data.item.year}/${data.item.application_type_id}/${data.item.evaluation_stage_id}`">
              審査画面
            </b-link>
          </template>
          <template v-slot:empty="scope">
            <p class="none-data">審査対象がありません。</p>
          </template>
        </b-table>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';
import api from '@/modules/api';
import CONST_AUTH from '@/constants/auth';

export default {
  name: 'CmsTop',
  components: {},
  data() {
    return {
      heads: {
        app: [
          {
            key: 'name',
            label: '助成プログラム',
            sortable: true,
            class: 'name',
          },
          {
            key: 'appDate',
            label: '申請期間',
            sortable: true,
            class: 'appDate',
          },
          {
            key: 'counts',
            label: '件数',
            sortable: true,
            class: 'evaluations',
          },
          // {
          //   key: 'resultDate',
          //   label: '結果発表日',
          //   sortable: true,
          //   class: 'resultDate',
          // },
        ],
        report: [
          {
            key: 'name',
            label: '助成プログラム',
            sortable: true,
            class: 'name',
          },
          {
            key: 'adoptionCount',
            label: '採択数',
            sortable: true,
            class: 'adoptionCount count-wrap',
          },
          {
            key: 'reportCount',
            label: '報告数',
            sortable: true,
            class: 'reportCount count-wrap',
          },
          {
            key: 'receiptCount',
            label: '報告受領数',
            sortable: true,
            class: 'receiptCount count-wrap',
          },
          {
            key: 'reportDate',
            label: '報告期間',
            sortable: false,
            class: 'reportDate',
          },
        ],
      },
      showData: {
        app: [],
        report: [],
      },
      initData: {
        app: [],
        report: [],
      },
      yearOptions: {
        app: [],
        report: [],
      },
      selectedYears: {
        app: [],
        report: [],
      },
      evals: [],
      evalsHeader: [
        {
          key: 'name',
          label: '助成プログラム',
          sortable: true,
          class: 'name',
        },
        {
          key: 'stage',
          label: '審査段階',
          sortable: true,
          class: 'eval-stages count-wrap',
        },
        {
          key: 'term',
          label: '審査期間',
          sortable: true,
          class: 'eval-term count-wrap',
        },
        {
          key: 'all_count',
          label: '審査対象',
          sortable: true,
          class: 'eval-count count-wrap',
        },
        {
          key: 'is_not_done',
          label: '未審査',
          sortable: false,
          class: 'eval-count',
        },
        {
          key: 'is_done',
          label: '審査済',
          sortable: false,
          class: 'eval-count',
        },
        {
          key: 'link',
          label: '',
          sortable: false,
          class: 'eval-link',
        },
      ],
    };
  },
  methods: {
    async initFetch(promiseFuncs) {
      const res = await api.all(promiseFuncs)
        .catch((error) => {
          console.log(error);
          return false;
        });
      if (res !== false) {
        res.map((list) => {
          const listData = list.data;
          const listType = listData.type;
          this.initData[listType] = listData.list;
          this.yearOptions[listType] = this.setYerSelect(listData.selectYears);
          this.selectedYears[listType] = listData.selectYears.shift();
          this.showData[listType] = this.getSearchYearData(
            this.initData[listType], this.selectedYears[listType],
          );
          if (listData.hasAccessAuth) {
            this.heads[listType].push({
              key: 'editButton',
              label: '',
              sortable: false,
              class: 'link',
            });
          }
          return true;
        });
      }
    },
    async evalFetch() {
      const res = await api.send('/api/evaluation/list')
        .catch((error) => {
          console.log(error);
          return false;
        });
      if (res === false) {
        console.log(res);
        return;
      }
      this.evals = res.data.list;
    },
    /**
     * select項目用に整形
     * @param [Array] Years
     * @return [object]
     */
    setYerSelect(Years) {
      return Years.map((year) => {
        return { value: year, text: year };
      });
    },
    /**
     * 年度でリストを絞り込み
     * @param  [Object] list
     * @param  [Number] selectedYear
     * @return [Object]
     */
    getSearchYearData(list, selectedYear) {
      return list.filter((target) => {
        return (target.year === selectedYear);
      });
    },
    /**
     * 取得してきた最初の情報をコピー
     * @param  [Object]} initData
     * @return [Object]}
     */
    dataReset(initData) {
      return JSON.parse(JSON.stringify(initData));
    },
    /**
     * 年度選択時のイベント
     * @param  [String] type
     */
    yearChange(type) {
      this.showData[type] = this.dataReset(this.initData[type]);
      this.showData[type] = this.getSearchYearData(this.showData[type], this.selectedYears[type]);
    },
  },
  computed: {
    // 財団管理者
    group() {
      return this.$store.state.auth.authes.includes(CONST_AUTH.AUTH.GROUP);
    },
    // 評価担当者
    evaluation() {
      return this.$store.state.auth.authes.includes(CONST_AUTH.AUTH.EVALUATION);
    },
    // 審査委員長
    chairman() {
      return this.$store.state.auth.authes.includes(CONST_AUTH.AUTH.CHAIRMAN);
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading', 'CmsTop');
    const promiseFuncs = [api.send('/api/appType/list')];
    await this.initFetch(promiseFuncs);
    if (this.evaluation) {
      await this.evalFetch();
    }
    this.$store.dispatch('page/offLoading', 'CmsTop');
  },
};
</script>
<style>
  .CmsTop td{
    vertical-align: baseline;
  }
  #applicantWrap .title,
  #evaluationWrap .title {
    color: #333;
    font-weight: normal;
  }
  #evaluationWrap .title {
    max-width: 180px !important;
  }
  #applicantWrap .max-w80 {
    max-width: 140px;
  }
  #evaluationWrap .max-w80 {
    max-width: 180px !important;
  }
  #applicantWrap .max-w120,
  #evaluationWrap .max-w120 {
    max-width: 120px;
    font-size: 18px;
  }
  #applicantWrap .max-w80,
  #evaluationWrap .max-w80,
  #applicantWrap .container-fluid,
  #evaluationWrap .container-fluid {
    margin: 0;
    padding: 0;
  }
  #applicantWrap table,
  #evaluationWrap table {
    border:none;
  }
  #applicantWrap .tableHead,
  #evaluationWrap .tableHead {
    background: #6E6E6E;
    color: #FFF;
    border:solid 1px #6E6E6E;
    height: 40px !important;
    word-break: keep-all;
  }
  #applicantWrap .tableHead th,
  #evaluationWrap .tableHead th {
    border:solid 1px #CCCCCC;
  }

  #applicantWrap tbody td,
  #evaluationWrap tbody td
  {
    vertical-align: middle;
  }
/*カラム関連*/
  td.name{
    word-break: break-all;
  }
  td.resultDate{
    width: 200px;
  }
  td.appDate,
  td.evaluations,
  td.reportDate{
    width: 380px;
  }
  td.adoptionCount,
  td.reportCount,
  td.receiptCount{
    width: 120px;
  }
  td.link{
    width: 100px;
  }
  #applicantWrap tbody tr,
  #evaluationWrap tbody tr
  {
    background: #FFFFFF
  }
  #evaluationWrap tbody tr td,
  #applicantWrap tbody tr td
  {
    border-top: 1px solid #CCCCCC;
  }

  #cmsTopEvalsTable .eval-count,
  #cmsTopEvalsTable .eval-stages {
    width: 70px;
  }
  #cmsTopEvalsTable .eval-link {
    width: 100px;
  }
  #cmsTopEvalsTable .eval-term {
    width: 400px;
  }
</style>
<style scoped>
  .tableWrap{
    padding: 0 5vh;
  }
  table {
    border: solid 1px #d6d6d6;
  }
  .center{
     vertical-align:super;
  }
  span {
    display: inline-block;
  }
  #applicantTable tbody tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05);
  }

  #applicantTable td, #applicantTable th{
    font-size: 14px;
  }

  #applicantTable .th0{
    width: 25%;
  }
  .table th, .table td {
    vertical-align: baseline !important;
  }
  #year{
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .none-data{
    margin:0;
    text-align: center;
  }
</style>
